import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchengin  } from '@fortawesome/free-brands-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./header.module.css"
import SearchBox from "./search_box"
import SiteImage from "./site_image"
import SubscribeButton from "./subscribe_button"
// import SiteLogo from "./site_logo"

const Header = ({ siteTitle }) => {

  const openModal = () => {
    document.getElementById("search-modal").style.display = "block";
  }
  const closeModal = () => {
    document.getElementById("search-modal").style.display = "none";
  }
    
return (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div className="header-grid">
        <div className="header-menu">
          <h1 style={{ margin: 0 }}>
            <Link to="/">
              {siteTitle}
            </Link>
          </h1>
          <ul className={styles.menu}>
            <li key="home" className={styles["menuItem"]}>
              <Link to="/">Home</Link>
            </li>
            <li key="books" className={styles["menuItem"]}>
              Books
              <ul>
                <li key="pooles" className={styles["menuItem"]}>
                  <Link to="/pooles">Poole's Diner</Link>
                </li>
                <li key="drr" className={styles["menuItem"]}>
                  <Link to="/drr">Deep Run Roots</Link>
                </li>
                <li key="drinkingfrench" className={styles["menuItem"]}>
                  <Link to="/drinkingfrench">Drinking French</Link>
                </li>
                <li key="wholehogbbq" className={styles["menuItem"]}>
                  <Link to="/wholehogbbq">Whole Hog BBQ</Link>
                </li>
                <li key="brownsugarkitchen" className={styles["menuItem"]}>
                  <Link to="/brownsugarkitchen">Brown Sugar Kitchen</Link>
                </li>
                <li key="sonofasouthernc" className={styles["menuItem"]}>
                  <Link to="/sonofasouthernc">Son of a Southern Chef</Link>
                </li>
                <li key="outlanderkitchen" className={styles["menuItem"]}>
                  <Link to="/outlanderkitchen">Outlander Kitchen (1 & 2)</Link>
                </li>
                <li key="misterjius" className={styles["menuItem"]}>
                  <Link to="/misterjius">Mister Jiu's in Chinatown</Link>
                </li>
                <li key="thiswillmakeittastegood" className={styles["menuItem"]}>
                  <Link to="/thiswillmakeittastegood">This Will Make It Taste Good</Link>
                </li>
                <li key="itsalwaysfreezerseason" className={styles["menuItem"]}>
                  <Link to="/itsalwaysfreezerseason">It's Always Freezer Season</Link>
                </li>
                <li key="minecraft" className={styles["menuItem"]}>
                  <Link to="/minecraft">The Minecrafter's Cookbook</Link>
                </li>
                <li key="atkkidsbakingbook" className={styles["menuItem"]}>
                  <Link to="/atkkidsbakingbook">
                    The Complete Baking Book for Young Chefs
                  </Link>
                </li>
                <li key="iamfromhere" className={styles["menuItem"]}>
                  <Link to="/iamfromhere">
                    I Am From Here
                  </Link>
                </li>
                <li key="meathead" className={styles["menuItem"]}>
                  <Link to="/meathead">
                    Meathead
                  </Link>
                </li>
                <li key="rodneyscottsworldofbbq" className={styles["menuItem"]}>
                  <Link to="/rodneyscottsworldofbbq">
                    Rodney Scott's World of BBQ
                  </Link>
                </li>
                <li key="nothingfancy" className={styles["menuItem"]}>
                  <Link to="/nothingfancy">
                    Nothing Fancy
                  </Link>
                </li>
                <li key="milkstreet">
                  <Link to="/milkstreet">
                    Milk Street
                  </Link>
                </li>
                <li key="aroundmyfrenchtable">
                  <Link to="/aroundmyfrenchtable">
                    Around My French Table
                  </Link>
                </li>
              </ul>
            </li>
            <li key="others" className={styles["menuItem"]}>
              <Link to="/heathers">Other Videos</Link>
            </li>
            <li key="posts" className={styles["menuItem"]}>
              <Link to="/posts">All Posts</Link>
            </li>
            <li key="about" className={styles["menuItem"]}>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
        <div className="header-search">
          <SearchBox />
        </div>
        <div className="header-search-icon">
          <button onClick={openModal}>
            <FontAwesomeIcon icon={faSearchengin} />
          </button>
        </div>
        <div className="header-subscribe">
          <SubscribeButton />
        </div>
        <div className="header-icon">
          <SiteImage />
        </div>
      </div>
    </div>
    <div id="search-modal" className="modal-window">
      <button onClick={closeModal} title="Close" className="modal-close">
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <SearchBox showSubmitButton={true} />
    </div>
  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
